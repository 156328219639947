import React, { useState, useCallback } from "react";
import { getUrls, createDocument, uploadToAws } from '../../service';

const NewOperationModal = ({  csrfToken, operationId }) => {
  const [name, setName] = useState('');
  const [file, setFile] = useState(null);

  const handleNameChange = useCallback((event) => {
    setName(event.target.value);
  });

  const handleFileChange = useCallback((e) => {
    const { target: { files: [file] } = {} } = e;
    setFile(file);
  });

  const handleSubmit = useCallback(() => {
    const { type } = file;
    console.log(file, type)
    const data = {
      name,
      type,
      operationId,
    };
    getUrls(operationId, csrfToken, data).then(
      response => getUrlsSuccess(response.data),
      () => alert("There was an error creating the document"),
    );
  });

  const getUrlsSuccess = useCallback((urls) => {
    const { post_url, get_url } = urls;
    console.log(post_url, file, urls)
    uploadToAws(post_url, file).then(
      () => uploadSuccess(get_url),
      err => alert("There was an error creating the document"),
    );
  });

  const uploadSuccess = useCallback((get_url) => {
    const data = {
      name,
      url: get_url,
      operation_id: operationId,
      filetype: file.type,
    };
    createDocument(operationId, csrfToken, data).then(
      () => {
        alert("Documento creado con éxito");
        window.location.reload();
      },
      (err) => alert("There was an error creating the document"),
    )
  });

  return (
    <React.Fragment>
      <div className="modal" id="new-document-modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">New Document</h5>
              <button type="button" className="close" data-dismiss="modal" />
            </div>
            <div className="modal-body">
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  value={name}
                  onChange={handleNameChange}
                />
              </div>
              <div className="input-group mb-3">
                <input
                  type="file"
                  className="form-control"
                  placeholder="Document"
                  onChange={handleFileChange}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-success"
                onClick={handleSubmit}
                disabled={!name || !file}
                type="button"
              >
                Create
              </button>
              <button type="button" data-dismiss="modal" className="btn">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default NewOperationModal;
