import React from "react";
import UopsLabelWarehouse from "../UopsLabelWarehouse";
import WarehouseReportForm from "./WarehouseReportForm";
import {
  listWarehouses,
  destroyWarehouse,
  createWarehouse
} from "../../service";
import Select from "react-select";

class WarehouseManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      warehouses: [],
      nwInfo: '',
      nwUnit: '',
      nwType: '',
      nwProyected: 0
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const { operationId, csrfToken } = this.props;
    listWarehouses(operationId, csrfToken).then(
      response => this.listWarehousesSuccess(response.data),
      () => this.listWarehousesError()
    );
  };

  listWarehousesSuccess = response => {
    let data = {};
    let warehouses = response.warehouses;
    warehouses.forEach(w => {
      data[w.id] = {
        qtty: 0,
        day: "",
        turn: ""
      };
    });
    this.setState({ data, warehouses });
  };

  listWarehousesError = () => {
    alert("An error occured fetching warehouses");
  };

  onEditWarehouse = id => {
    window.location.href = `/operation_warehouses/${id}/edit`;
  };

  onDestroyWarehouse = id => {
    const { csrfToken } = this.props;
    if (
      window.confirm(
        "Are you sure you want to delete this hold? All its reports will be deleted"
      )
    )
      destroyWarehouse(id, csrfToken).then(
        () => this.onWarehouseDestroyedSuccess(),
        () => this.onWarehouseDestroyedError()
      );
  };

  onWarehouseDestroyedSuccess = () => {
    this.loadData();
  };

  onWarehouseDestroyedError = () => {
    alert("An error occured destroying the hold");
  };

  onCreateWarehouse = () => {
    const { nwInfo, nwUnit, nwProyected, nwType } = this.state;
    const { csrfToken, operationId } = this.props;
    const data = {
      unit: nwUnit.value,
      proyected: nwProyected,
      info: nwInfo,
      load_type: nwType.value,
      operation_id: operationId,
      real: 0
    };
    createWarehouse(data, csrfToken).then(
      () => this.onCreateWarehouseSuccess(),
      () => this.onCreateWarehouseError()
    );
  };

  onCreateWarehouseSuccess = () => {
    this.loadData();
    this.setState({
      nwInfo: '',
      nwUnit: '',
      nwProyected: 0,
      nwType: ''
    });
  };

  onCreateWarehouseError = () => {
    alert("An error occurred creating the hold");
  };

  newWarehouseDataValid = () => {
    let ret = true;
    const { nwInfo, nwUnit, nwProyected, nwType } = this.state;
    ret =
      nwInfo && nwUnit && nwType && nwProyected && nwProyected > 0 && nwInfo.length > 3;
    return ret;
  };

  capitalizeFirstLetter = (string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "-";
  }

  renderNewWarehouseForm = () => {
    const { units, types } = this.props;
    const { nwInfo, nwProyected, nwUnit, nwType } = this.state;
    return (
      <React.Fragment>
        <div className="row col-12 mt-2">
          <input
            className="form-control col-xs-12 col-md-3 mr-sm-1 mt-1"
            type="text"
            placeholder="Info"
            value={nwInfo}
            onChange={e => this.setState({ nwInfo: e.target.value })}
          />
          <input
            className="form-control col-xs-12 col-md-3 mt-1 mr-1 mr-sm-0"
            type="number"
            placeholder="Projected"
            value={nwProyected}
            onChange={e => this.setState({ nwProyected: e.target.value })}
          />
        <div className="col-xs-12 col-sm-3 p-0 col-md-3">
            <Select
              options={types.map(t => ({ label: t.name, value: t.value }))}
              placeholder={"Type"}
              className="w-100 mt-1 ml-1"
              value={nwType}
              onChange={u => this.setState({ nwType: u })}
            />
          </div>
          <div className="col-xs-12 col-sm-3 p-0 col-md-2">
            <Select
              options={units.map(u => ({ label: u.unit, value: u.unit }))}
              placeholder={"Unit"}
              className="w-100 mt-1 ml-1"
              value={nwUnit}
              onChange={u => this.setState({ nwUnit: u })}
            />
          </div>
        </div>
        <div className="col-xs-12 pl-0 mt-1 col-md-3">
          <button
            onClick={this.onCreateWarehouse}
            disabled={!this.newWarehouseDataValid()}
            className="btn btn-sm btn-success"
          >
            Add
          </button>
        </div>
      </React.Fragment>
    );
  };

  calculateBalance = warehouse => {
    const { real, proyected } = warehouse;
    const balance = proyected - real;
    return balance < 0 ? 0 : balance;
  }

  render() {
    const { warehouses } = this.state;
    const { canReport, csrfToken } = this.props;
    return (
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Hold Management</h3>
        </div>
        <div className="card-body">
          {warehouses.map((w, index) => {
            return (
              <React.Fragment key={w.id}>
                <div className="row group-overflow">
                  <h5 className="mr-1 mt-4">#{index + 1}</h5>
                  <div className="row col-12 col-sm-10 mt-2 mb-0">
                    <UopsLabelWarehouse label={"Info"} value={w.info} />
                    <UopsLabelWarehouse label={"Type"} value={this.capitalizeFirstLetter(w.load_type)} />
                    <UopsLabelWarehouse label={"Planified"} value={w.proyected} />
                    <UopsLabelWarehouse label={"Real"} value={w.real} />
                    <UopsLabelWarehouse label={"Balance"} value={this.calculateBalance(w)} />
                    <UopsLabelWarehouse label={"Unit"} value={w.unit} />
                  </div>
                  <div className="col-2 mt-2 mb-0">
                  {canReport ? (
                      <React.Fragment>
                        <div className={`col-xs-5 col-sm-2 col-md-1`}>
                          <label>{}</label>
                          <button
                            className="btn btn-default"
                            data-toggle="modal"
                            data-target={`#new-report-modal-${w.id}`}
                            type="button"
                          >
                            Add Report
                          </button>
                        </div>
                        <WarehouseReportForm id={w.id} csrf={csrfToken} loadData={this.loadData}/>
                      </React.Fragment>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 pl-0 mt-0">
                  <div
                    className="badge badge-primary mr-1"
                    onClick={() => this.onEditWarehouse(w.id)}
                  >
                    Edit
                  </div>
                  <div
                    className="badge badge-danger"
                    onClick={() => this.onDestroyWarehouse(w.id)}
                  >
                    Delete
                  </div>
                </div>
                <hr className="mt-1 mb-3"/>
              </React.Fragment>
            );
          })}
          {this.renderNewWarehouseForm()}
        </div>
      </div>
    );
  }
}

export default WarehouseManagement;
