import React from "react";

class UopsLabel extends React.Component {
  render() {
    const { label, value, extraClass = "" } = this.props;
    return (
      <div className="col-xs-5 col-sm-2">
        <div className={`form-group ${extraClass}`}>
          <label className="form-label">{label}</label>
          <div className="form-control-plaintext">{value}</div>
        </div>
      </div>
    );
  }
}

export default UopsLabel;
