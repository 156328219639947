
import React from 'react';
import Datetime from "react-datetime";
import moment from 'moment-timezone';

class UopsDate extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      time: ''
    }
  }
  componentDidMount(){
    const { value } = this.props;
    this.setState({
      time: moment(value).format("DD/MM/YYYY HH:mm")
    })
  }

  handleChange = time => {
    this.setState({ time });
  };

  render(){
    const { time } = this.state;
    const { placeholder, name } = this.props;
    return (
      <Datetime
        dateFormat="DD/MM/YYYY"
        timeFormat="HH:mm Z"
        inputProps={{ placeholder, name, className: 'tz-time form-control' }}
        value={time}
        onChange={this.handleChange}
      />
    )
  }
}

export default UopsDate;