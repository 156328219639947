import React, { Component } from "react";
import moment from "moment";

class SofEntryFragment extends Component {
  colorToClass = color => {
    switch (color) {
      case "Light red":
        return "dot-light-red";
      case "Green":
        return "dot-green";
      case "Dark red":
        return "dot-dark-red";
      case "Dark yellow":
        return "dot-dark-yellow";
      case "Light yellow":
        return "dot-light-yellow";
      case "Dark green":
        return "dot-dark-green"
      default:
        return "";
    }
  };

  formatDate = date => {
    const { entry: { gmt = 0 } = {} } = this.props;
    return moment.utc(date).utcOffset(gmt).format("DD/MM/YYYY HH:mm");
  };

  onDestroySofEntry = () => {
    const { entry } = this.props;
    if (window.confirm('Are you sure you wish to delete this entry?'))
      this.props.destroySofEntry(entry.id);
  }

  onEditSofEntry = () => {
    const { entry } = this.props;
    window.location = `/sof_entries/${entry.id}/edit`;
  }

  render() {
    const { entry } = this.props;
    return (
      <React.Fragment>
        <div className="row entry-container ml-3 mr-3">
          <div className="col-md-6 col-xs-12 remark-separator">
            <span className="float-right">{entry.category}</span>
            <h5>
              <span className={`dot ${this.colorToClass(entry.color)}`} />
              {entry.event}
            </h5>
            <span>
              {this.formatDate(entry.from)} - {this.formatDate(entry.to)}
            </span>
            <div className="w-100">
              <div className="badge badge-primary mr-1" onClick={this.onEditSofEntry}>
                Edit
              </div>
              <div className="badge badge-danger" onClick={this.onDestroySofEntry}>
                Delete
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xs-12">{ 
            entry.remark ? entry.remark : (<span className="no-remark">No remark</span>)
            }</div>
        </div>
        <hr className="mt-1 mb-1"/>
      </React.Fragment>
    );
  }
}

export default SofEntryFragment;
