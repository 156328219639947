import React, { Component } from "react";
import { updateSofEntry } from "../../service";
import Select from "react-select";
import Datetime from "react-datetime";
import moment from "moment";

class EditSofEntry extends Component {
  constructor(props) {
    super(props);
    let { sofEntriesTypes } = this.props;
    this.state = {
      selectedEntryType: null,
      fromTime: "",
      toTime: "",
      remark: "",
      customCategory: "",
      customEvent: "",
      validData: false,
      sofEntryOptions: sofEntriesTypes.map(o => ({
        value: o.id,
        label: `${o.event} - ${o.category}`
      }))
    };
  }

  componentDidMount() {
    const { entry, sofEntriesTypes, sofEntriesCategories } = this.props;
    const { sofEntryOptions } = this.state;
    if (entry.custom) {
      let auxSelectedCategory = sofEntriesCategories.filter(
        e => e.category === entry.category
      )[0];
      this.setState({
        customCategory: { label: auxSelectedCategory.category, value: auxSelectedCategory.id },
        customEvent: entry.event,
        custom: true,
        toTime: moment(entry.to),
        fromTime: moment(entry.from),
        remark: entry.remark
      });
    } else {
      let auxSelectedEntry = sofEntriesTypes.filter(
        e => e.event === entry.event && e.category === entry.category
      )[0];
      let auxEntryOption = sofEntryOptions.filter(
        e =>
          e.label === `${auxSelectedEntry.event} - ${auxSelectedEntry.category}`
      )[0];
      this.setState({
        selectedEntryType: auxEntryOption,
        custom: true,
        toTime: moment(entry.to),
        fromTime: moment(entry.from),
        custom: false,
        remark: entry.remark
      });
    }
  }

  handleEntryTypeChange = selectedEntryType => {
    this.setState({ selectedEntryType });
  };

  handleCustomCategoryChange = selected => {
    this.setState({
      customCategory: selected
    });
  };

  updateEntrySuccess = () => {
    const { operationId } = this.props;
    window.location.href = `/operations/${operationId}`
  };

  updateEntryError = error => {
    alert("An error occured creating SOF entry");
  };

  onSetFromTime = fromTime => {
    this.setState({ fromTime });
  };

  onSetToTime = toTime => {
    this.setState({ toTime });
  };

  onCustomEventChange = e => {
    this.setState({
      customEvent: e.target.value
    });
  };

  onRemarkChange = e => {
    this.setState({
      remark: e.target.value
    });
  };

  renderCustomView = () => {
    const { sofEntriesCategories } = this.props;
    const { customCategory, customEvent } = this.state;
    return (
      <React.Fragment>
        <div className="col-md-6 col-xs-12">
          <input
            type="text"
            className="form-control"
            placeholder="Enter an Event"
            value={customEvent}
            disabled={true}
            readonly
            onChange={this.onCustomEventChange}
          />
        </div>
        <div className="col-md-6 col-xs-12">
          <Select
            value={customCategory}
            onChange={this.handleCustomCategoryChange}
            isDisabled={true}
            options={sofEntriesCategories.map(c => ({
              label: c.category,
              value: c.id
            }))}
            placeholder={"Select a category"}
          />
        </div>
      </React.Fragment>
    );
  };

  renderNonCustomView = () => {
    const { selectedEntryType, sofEntryOptions } = this.state;
    return (
      <Select
        value={selectedEntryType}
        onChange={this.handleEntryTypeChange}
        options={sofEntryOptions}
        isDisabled={true}
        placeholder={"Select an event and category"}
        className="col-12"
      />
    );
  };

  onUpdateEntry = () => {
    const {
      custom,
      customCategory,
      customEvent,
      fromTime,
      toTime,
      selectedEntryType,
      remark
    } = this.state;
    const { csrfToken, sofEntriesTypes, entry } = this.props;
    let aux_entry;
    if (!custom)
      aux_entry = sofEntriesTypes.filter(e => e.id === selectedEntryType.value)[0];
    const options = {
      from: fromTime.format(),
      to: toTime.format(),
      remark
    };
    updateSofEntry(entry.id, options, csrfToken).then(
      response => this.updateEntrySuccess(response),
      error => this.updateEntryError(error)
    );
  };

  validData = () => {
    const {
      custom,
      customCategory,
      fromTime,
      toTime,
      selectedEntryType
    } = this.state;
    if (custom) {
      return (
        customCategory &&
        fromTime &&
        toTime &&
        moment(toTime).isSameOrAfter(fromTime)
      );
    } else {
      return (
        selectedEntryType &&
        fromTime &&
        toTime &&
        moment(toTime).isSameOrAfter(fromTime)
      );
    }
  };

  render() {
    const { custom, remark, fromTime, toTime } = this.state;
    let validData = this.validData();
    return (
      <React.Fragment>
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Edit Entry</h3>
          </div>
          <div className="card-body">
            <div className="col-12 row mb-3">
              {custom ? this.renderCustomView() : this.renderNonCustomView()}
            </div>
            <div className="col-12 row mb-3">
              <div className="col-md-6 col-xs-12">
                <Datetime
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm"
                  onChange={this.onSetFromTime}
                  inputProps={{ placeholder: "Select From time" }}
                  value={fromTime}
                />
              </div>
              <div className="col-md-6 col-xs-12">
                <Datetime
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm"
                  onChange={this.onSetToTime}
                  inputProps={{ placeholder: "Select To time" }}
                  value={toTime}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group row pl-3 pr-6">
                <textarea
                  rows="5"
                  className="form-control w-100"
                  placeholder="Remark"
                  maxLength={150}
                  value={remark}
                  onChange={this.onRemarkChange}
                />
              </div>
            </div>
            <div className="col-12 mt-2">
              <button
                className="btn btn-sm btn-default"
                disabled={!validData}
                onClick={this.onUpdateEntry}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default EditSofEntry;
