import React, { Component } from "react";
import { listSofEntries, destroySofEntry } from "../../service";

import SofEntryFragment from "./SofEntryFragment";
import NewSofEntry from "./NewSofEntry";

class SofManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sofEntries: []
    };
  }

  componentDidMount() {
    this.loadData();
  }

  onCreateEntry = () => {
    this.loadData();
  }

  loadData = () => {
    const { operationId, csrfToken } = this.props;
    listSofEntries(operationId, csrfToken).then(
      response => this.getEntriesSuccess(response.data),
      error => this.getEntriesError(error)
    );
  }

  getEntriesSuccess = sofEntries => {
    this.setState({ sofEntries });
  };

  getEntriesError = () => {
    alert("An error occured obtaining SOF entries");
  };

  destroySofEntry = (id) => {
    const { csrfToken } = this.props;
    destroySofEntry(id, csrfToken).then(
      () => this.destroySofEntrySuccess(),
      () => this.destroySofEntryError()
    )
  }
  destroySofEntrySuccess = () => {
    this.loadData();
  };

  destroySofEntryError = () => {
    alert("An error occured deleting the SOF entry");
  };

  render() {
    const { sofEntries } = this.state;
    const { sofEntriesTypes, sofEntriesCategories, csrfToken, operationId, addEntries } = this.props;
    return (
      <React.Fragment>
        {
          addEntries ?
          <NewSofEntry
            sofEntriesTypes={sofEntriesTypes}
            sofEntriesCategories={sofEntriesCategories}
            csrfToken={csrfToken}
            operationId={operationId}
            onCreateEntry={this.onCreateEntry}
          />
          : null
        }
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">History</h3>
          </div>
          {sofEntries.map(e => {
            return <SofEntryFragment key={e.id} entry={e} destroySofEntry={this.destroySofEntry}/>;
          })}
        </div>
      </React.Fragment>
    );
  }
}

export default SofManagement;
