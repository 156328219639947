import "./table.css";

window.initializeTable = (elementID, config) => {
  let table = $(`#${elementID}`);
  let dTable = table.dataTable({
    ...config,
    dom: '<"top"i>rt<"bottom"pf><"clear">',
    bPaginate: true,
    bLengthChange: false,
    bFilter: true,
    bInfo: false,
    bAutoWidth: false,
    pageLength: 10,
    responsive: true
  });
  document.addEventListener("turbolinks:before-cache", function() {
    if (table !== null) {
      table.fnDestroy();
      table = null;
    }
  });
  return dTable;
};
