import './jquery.dataTables.columnFilter'

window.setUserForm = function(){
  var companySelect = $('#company_select')
  $('#role_select').on('change', function(e){
    var value = e.target.value;
    if(value === 'client')
      companySelect.attr('disabled', false)
    else
      companySelect.attr('disabled', 'disabled')
  })
}