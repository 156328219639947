import React, { Component } from "react";
import { searchOperation } from "../../service";
import OperationWarehouse from "./OperationWarehouse";

class NewOperationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      waveCode: "",
      searching: false,
      searchable: false,
      warehouseData: [],
      validWarehouseData: false
    };
  }

  onSearchInputChange = e => {
    const text = e.target.value;
    this.setState({
      waveCode: text,
      searchable: text.length > 3
    });
  };

  onSearchSuccess = response => {
    this.setState(
      {
        searching: false
      },
      () => (window.location = response.data.operation_url)
    );
  };

  onSearchError = () => {
    alert("Wave Code not found");
    this.setState({
      searching: false
    });
  };

  onSearchButtonClick = () => {
    const { waveCode, warehouseData } = this.state;
    const { csrfToken } = this.props;
    this.setState({
      searching: true
    });
    let auxWarehouseData = Object.assign([], warehouseData);
    auxWarehouseData = auxWarehouseData.map(d => {
      d.unit = d.unit.value;
      d.load_type = d.load_type.value;
    })
    searchOperation(waveCode, warehouseData, csrfToken).then(
      response => this.onSearchSuccess(response),
      () => this.onSearchError()
    );
  };

  onWarehouseDataUpdate = warehouseData => {
    this.setState({
      warehouseData,
      validWarehouseData: this.validWarehouseData(warehouseData)
    });
  };

  validWarehouseData = (warehouseData) => {
    let ret = true;
    warehouseData.forEach(
      d =>
        (ret = ret &&
          d.proyected &&
          d.info &&
          d.unit &&
          d.proyected > 0 &&
          d.info.length > 3)
    );
    return ret;
  };

  render() {
    const { searching, waveCode, searchable, validWarehouseData } = this.state;
    const { units, types } = this.props;
    return (
      <React.Fragment>
        <div
          className="btn btn-sm"
          data-toggle="modal"
          data-target="#new-operation-modal"
        >
          New Operation
        </div>
        <div className="modal" id="new-operation-modal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">New Operation</h5>
                <button type="button" className="close" data-dismiss="modal" />
              </div>
              <div className="modal-body">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.onSearchInputChange}
                    value={waveCode}
                    placeholder="Wave Code"
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-success"
                      onClick={this.onSearchButtonClick}
                      disabled={searching || !(searchable && validWarehouseData) ? "disabled" : ""}
                      type="button"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <OperationWarehouse
                  units={units}
                  onWarehouseDataUpdate={this.onWarehouseDataUpdate}
                  types={types}
                />
              </div>
              <div className="modal-footer">
                <button type="button" data-dismiss="modal" className="btn">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NewOperationModal;
