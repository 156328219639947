import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';
import 'bootstrap/dist/js/bootstrap';
import 'datatables.net-bs4/js/dataTables.bootstrap4';

import '../components/table'
import '../assets/stylesheets';
import '../assets/images';
import 'utils';

import {$,jQuery} from 'jquery';

window.$ = $;
window.jQuery = jQuery;


Rails.start();
Turbolinks.start();// Support component names relative to this directory:
var componentRequireContext = require.context("components", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)
