import React from "react";
import { destroyReport, updateReport, listReports } from "../../service";

import moment from "moment";

class ReportsManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: [],
      updatingReport: false
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const { warehouseId, csrfToken } = this.props;
    listReports(warehouseId, csrfToken).then(
      response => this.onListReportsSuccess(response.data),
      () => this.onListReportsError()
    );
  };

  onListReportsSuccess = reports => {
    this.setState({
      reports
    });
  };

  onListReportsError = () => {
    alert("An error occurred fetching the reports");
  };

  onUpdateReport = (id) => {
    const { csrfToken, warehouseId } = this.props;
    const { reports } = this.state;
    const data = {
      qtty: reports.filter(r => r.id === id)[0].qtty
    };
    updateReport(warehouseId, id, data, csrfToken).then(
      () => this.onUpdateReportSuccess(),
      () => this.onUpdateReportError()
    );
  };

  onUpdateReportSuccess = () => {
    this.loadData();
    alert("Report updated")
  };

  onUpdateReportError = () => {
    alert("An error occurred updating the report");
  };

  onDestroyReport = id => {
    const { csrfToken, warehouseId } = this.props;
    if(window.confirm("Are you sure you want to delete this report?"))
      destroyReport(warehouseId, id, csrfToken).then(
        () => this.onDestroyReportSuccess(),
        () => this.onDestroyReportError()
      );
  };

  onDestroyReportSuccess = () => {
    this.loadData();
  };

  onDestroyReportError = () => {
    alert("An error occurred updating the report");
  };

  onReportQttyChange = (e, id) => {
    const { reports } = this.state;
    let auxReports = Object.assign([], reports);
    auxReports.map(r => {
      if (r.id === id) r.qtty = e.target.value;
    });
    this.setState({ reports: auxReports });
  };

  render() {
    const { reports } = this.state;
    return (
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Reports Management</h3>
        </div>
        <div className="card-body">
          {reports.map(r => {
            return (
              <React.Fragment key={r.id}>
                <div className="row">
                  <div className="col-1">
                    <div className="form-group">
                      <label className="form-label">Id</label>
                      <div className="form-control-plaintext">{r.id}</div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <label className="form-label">Quantity</label>
                      <input
                        className="form-control"
                        type="number"
                        value={r.qtty}
                        onChange={e => this.onReportQttyChange(e, r.id)}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <label className="form-label">Day and Time</label>
                      <div className="form-control-plaintext">
                        {moment(r.day).format("DD/MM/YYYY HH:mm")}
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <label className="form-label">Created</label>
                      <div className="form-control-plaintext">
                        {moment(r.created_at).format("DD/MM/YYYY HH:mm")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-100">
                  <div
                    className="badge badge-primary mr-1"
                    onClick={() => this.onUpdateReport(r.id)}
                  >
                    Update
                  </div>
                  <div
                    className="badge badge-danger"
                    onClick={() => this.onDestroyReport(r.id)}
                  >
                    Delete
                  </div>
                </div>
                <hr className="mt-1 mb-1" />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  }
}

export default ReportsManagement;
