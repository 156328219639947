import React, { Component } from "react";
import { createSofEntry } from "../../service";
import Select from "react-select";
import Datetime from "react-datetime";
import moment from "moment";

class NewSofEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      selectedEntryType: null,
      fromTime: "",
      toTime: "",
      remark: "",
      customCategory: "",
      customEvent: "",
      custom: false,
      validData: false,
      gmt: 0,
      sofEntryOptions: props.sofEntriesTypes.map(o => ({
        value: o.id,
        label: `${o.event} - ${o.category}`
      }))
    };
  }

  handleEntryTypeChange = selectedEntryType => {
    this.setState({ selectedEntryType });
  };

  handleCustomCategoryChange = selected => {
    this.setState({
      customCategory: selected
    });
  };

  createEntrySuccess = () => {
    this.props.onCreateEntry();
    this.setState({
      selectedEntryType: null,
      fromTime: "",
      toTime: "",
      remark: "",
      customCategory: "",
      customEvent: "",
      custom: false,
      validData: false
    });
  };

  createEntryError = error => {
    alert("An error occured creating SOF entry");
  };

  onSetFromTime = fromTime => {
    this.setState({ fromTime });
  };

  onSetToTime = toTime => {
    this.setState({ toTime });
  };

  onCustomSwitchChange = () => {
    this.setState({
      custom: !this.state.custom
    });
  };

  onCustomEventChange = e => {
    this.setState({
      customEvent: e.target.value
    });
  };

  onRemarkChange = e => {
    this.setState({
      remark: e.target.value
    });
  };

  renderCustomView = () => {
    const { sofEntriesCategories } = this.props;
    const { customCategory, customEvent } = this.state;
    return (
      <React.Fragment>
        <div className="col-md-6 col-xs-12 mb-sm-1">
          <input
            type="text"
            className="form-control"
            placeholder="Enter an Event"
            value={customEvent}
            onChange={this.onCustomEventChange}
          />
        </div>
        <div className="col-md-6 col-xs-12 mb-sm-1">
          <Select
            value={customCategory}
            onChange={this.handleCustomCategoryChange}
            options={sofEntriesCategories.map(c => ({
              label: c.category,
              value: c.id
            }))}
            placeholder={"Category"}
          />
        </div>
      </React.Fragment>
    );
  };

  renderNonCustomView = () => {
    const { selectedEntryType, sofEntryOptions } = this.state;
    return (
      <Select
        value={selectedEntryType}
        onChange={this.handleEntryTypeChange}
        options={sofEntryOptions}
        placeholder={"Select an event and category"}
        className="col-12"
      />
    );
  };

  onAddEntry = () => {
    const {
      custom,
      customCategory,
      customEvent,
      fromTime,
      toTime,
      selectedEntryType,
      remark
    } = this.state;
    const { csrfToken, sofEntriesTypes, operationId } = this.props;
    let entry;
    if (!custom)
      entry = sofEntriesTypes.filter(e => e.id === selectedEntryType.value)[0];
    const gmt = fromTime.utcOffset();
    const options = {
      from: fromTime.format(),
      to: toTime.format(),
      category: custom ? customCategory.label : entry.category,
      event: custom ? customEvent : entry.event,
      custom,
      remark,
      gmt,
    };
    createSofEntry(options, operationId, csrfToken).then(
      response => this.createEntrySuccess(response),
      error => this.createEntryError(error)
    );
  };

  validData = () => {
    const {
      custom,
      customCategory,
      fromTime,
      toTime,
      selectedEntryType
    } = this.state;
    if (custom) {
      return (
        customCategory &&
        fromTime &&
        toTime &&
        moment(toTime).isSameOrAfter(fromTime)
      );
    } else {
      return (
        selectedEntryType &&
        fromTime &&
        toTime &&
        moment(toTime).isSameOrAfter(fromTime)
      );
    }
  };

  render() {
    const { custom, remark, fromTime, toTime } = this.state;
    let validData = this.validData();
    return (
      <React.Fragment>
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Create Entry</h3>
            <label className="custom-switch custom-entry-switch">
              <input
                type="checkbox"
                className="custom-switch-input"
                onChange={this.onCustomSwitchChange}
                checked={custom}
              />
              <span className="custom-switch-indicator" />
              <span className="custom-switch-description">Custom</span>
            </label>
          </div>
          <div className="card-body">
            <div className="col-12 row mb-3">
              {custom ? this.renderCustomView() : this.renderNonCustomView()}
            </div>
            <div className="col-12 row mb-3">
              <div className="col-md-6 col-xs-12 mb-sm-1">
                <Datetime
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm"
                  onChange={this.onSetFromTime}
                  inputProps={{ placeholder: "Select From time" }}
                  value={fromTime}
                />
              </div>
              <div className="col-md-6 col-xs-12 mb-sm-1">
                <Datetime
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm"
                  onChange={this.onSetToTime}
                  inputProps={{ placeholder: "Select To time" }}
                  value={toTime}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group row pl-3 pr-6">
                <textarea
                  rows="5"
                  className="form-control w-100"
                  placeholder="Remark"
                  maxLength={150}
                  value={remark}
                  onChange={this.onRemarkChange}
                />
              </div>
            </div>
            <div className="col-12 mt-2">
              <button
                className="btn btn-sm btn-default"
                disabled={!validData}
                onClick={this.onAddEntry}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NewSofEntry;
