import React from "react";

class UopsLabelWarehouse extends React.Component {
  render() {
    const { label, value, extraClass = "mb-0", size = "2" } = this.props;
    return (
      <div className={`col-xs-5 col-sm-2 col-md-${size}`}>
        <div className={`form-group ${extraClass}`}>
          <label className="form-label">{label}</label>
          <div className="form-control-plaintext">{value}</div>
        </div> 
      </div>
    );
  }
}

export default UopsLabelWarehouse;
