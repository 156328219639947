const axios = require("axios");

export const searchOperation = (keyword, warehouseData, csrfToken) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.headers.common["Accept"] = "application/json";
  return axios.post(`operations/search?keywords=${keyword}`, {
    operation: {
      warehouse_data: warehouseData
    },
    withCredentials: true
  });
};

export const createSofEntry = (data, operationId, csrfToken) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.headers.common["Accept"] = "application/json";
  return axios.post(`/sof_entry?operation_id=${operationId}`, {
    ...data,
    withCredentials: true
  });
};

export const createReport = (data, warehouseId, csrfToken) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.headers.common["Accept"] = "application/json";
  return axios.post(`/operation_warehouses/${warehouseId}/report`, {
    ...data,
    withCredentials: true
  });
};

export const createWarehouse = (data, csrfToken) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.headers.common["Accept"] = "application/json";
  return axios.post(`/operation_warehouses/`, {
    ...data,
    withCredentials: true
  });
};

export const destroyReport = (warehouseId, reportId, csrfToken) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.headers.common["Accept"] = "application/json";
  return axios.delete(`/operation_warehouses/${warehouseId}/destroy_report?report_id=${reportId}`, {
    withCredentials: true
  });
};

export const updateReport = (warehouseId, reportId, data, csrfToken) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.headers.common["Accept"] = "application/json";
  return axios.put(`/operation_warehouses/${warehouseId}/update_report?report_id=${reportId}`, {
    ...data,
    withCredentials: true
  });
};

export const destroyWarehouse = (warehouseId, csrfToken) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.headers.common["Accept"] = "application/json";
  return axios.delete(`/operation_warehouses/${warehouseId}`, {
    withCredentials: true
  });
};

export const destroySofEntry = (sofEntryId, csrfToken) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.headers.common["Accept"] = "application/json";
  return axios.delete(`/sof_entries/${sofEntryId}`, {
    withCredentials: true
  });
};

export const updateSofEntry = (id, data, csrfToken) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.headers.common["Accept"] = "application/json";
  return axios.put(`/sof_entries/${id}`, {
    ...data,
    withCredentials: true
  });
};

export const listSofEntries = (operationId, csrfToken) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.headers.common["Accept"] = "application/json";
  return axios.get(`/sof_entry?operation_id=${operationId}`, {
    withCredentials: true
  });
};

export const listReports = (warehouseId, csrfToken) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.headers.common["Accept"] = "application/json";
  return axios.get(`/operation_warehouses/${warehouseId}/index_report`, {
    withCredentials: true
  });
};

export const listWarehouses = (operationId, csrfToken) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.headers.common["Accept"] = "application/json";
  return axios.get(`/operations/${operationId}/warehouses`, {
    withCredentials: true
  });
};

export const sendNotification = (operationId, data, csrfToken) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.headers.common["Accept"] = "application/json";
  return axios.post(`/operations/${operationId}/notifications`, {
    ...data,
    withCredentials: true
  });
};

export const getUrls = (operationId, csrfToken, data) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.headers.common["Accept"] = "application/json";
  return axios.post(`/operations/${operationId}/documents/upload`, {
    ...data,
    withCredentials: true,
  });
};

export const createDocument = (operationId, csrfToken, data) => {
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
  axios.defaults.headers.common["Accept"] = "application/json";
  return axios.post(`/operations/${operationId}/documents/`, {
    ...data,
    withCredentials: true,
  });
};

export const uploadToAws = (url, file) => {
  const options = {
    headers: {"Content-Type": file.type, 'acl': 'public-read'},
  }
  return axios.put(url, file, options);
};
