/* eslint-disable linebreak-style */
import React, { Component } from 'react';
import Datetime from 'react-datetime';
import Select from 'react-select';
import { createReport } from '../../service';

const TURNS = [
  {
    value: 1,
    label: 'Shift 1'
  },
  {
    value: 2,
    label: 'Shift 2'
  },
  {
    value: 3,
    label: 'Shift 3'
  }
];

class WarehouseReportForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qtty: '',
      day: '',
      turn: ''
    };
  }

  onQttyChange = e => {
    const { value } = e.target;
    if (value < 0) return;
    this.setState({ qtty: value });
  };

  onDayChange = e => {
    const value = e.format ? e.format('DD-MM-YYYY') : e;
    this.setState({ day: value });
  };

  onTurnChange = turn => {
    this.setState({
      turn
    });
  };

  onSubmit = () => {
    const { qtty, day, turn } = this.state;
    if (qtty < 0 || !day || !turn) return;
    const { id, csrf } = this.props;
    createReport({ qtty, day, turn: turn.value }, id, csrf).then(
      () => this.onReportCreateSuccess(),
      () => this.onReportCreateError()
    );
  };

  onReportCreateSuccess = () => {
    alert('Report succesfully created');
    this.setState({ day: '', turn: '', qtty: '' });
    this.props.loadData();
  };

  onReportCreateError = () => {
    alert('An error occured creating the report');
  };

  render() {
    const { qtty, day, turn } = this.state;
    const { id } = this.props;
    return (
      <React.Fragment>
        <div className="modal" id={`new-report-modal-${id}`}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">New Report</h5>
                <button type="button" className="close" data-dismiss="modal" />
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="form-group col-xs-12 col-sm-4 col-md-6">
                    <label className="form-label">
                      Quantity
                      <input
                        type="number"
                        className="form-control"
                        onChange={e => this.onQttyChange(e)}
                        required={true}
                        value={qtty}
                      />
                    </label>
                  </div>
                  <div className="form-group col-xs-12 col-xs-4 col-md-6">
                    <label className="form-label">
                      Day
                      <Datetime
                        dateFormat="DD-MM-YYYY"
                        value={day}
                        timeFormat={false}
                        required
                        onChange={e => this.onDayChange(e)}
                      />
                    </label>
                  </div>
                  <div className="form-group col-xs-12 col-xs-4 col-md-6">
                    <label className="form-label">
                      Shift
                      <Select
                        value={turn}
                        options={TURNS}
                        placeholder={'Shift'}
                        required
                        onChange={e => this.onTurnChange(e)}
                        className="w-100 mt-1"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-success"
                  onClick={this.onSubmit}
                  type="button"
                >
                  Add Report
                </button>
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-danger"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default WarehouseReportForm;
