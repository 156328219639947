import React from "react";
import Select from "react-select";

class OperationWarehouse extends React.Component {
  constructor(props) {
    super(props);
    const { units, types } = this.props;
    this.state = {
      qtty: 1,
      data: [{ info: "", proyected: "", unit: "", load_type: "" }],
      unitForSelector: units.map(c => ({
        label: c.unit,
        value: c.unit
      })),
      typesForSelector: types.map(t => ({
        label: t.name,
        value: t.value
      }))
    };
  }

  onInfoChange = (id, e) => {
    let auxData = this.cloneData();
    auxData[id].info = e.target.value
    this.setData(auxData);
  }

  onProyectedChange = (id, e) => {
    let auxData = this.cloneData();
    auxData[id].proyected = e.target.value
    this.setData(auxData);
  }

  onUnitChange = (id, e) => {
    let auxData = this.cloneData();
    auxData[id].unit = e
    this.setData(auxData);
  }

  onTypeChange = (id, e) => {
    let auxData = this.cloneData();
    auxData[id].load_type = e;
    this.setData(auxData)
  }

  onRemoveWarehouse = (index) => {
    let auxData = this.cloneData();
    const { qtty } = this.state;
    auxData.splice(index, 1);
    this.setState({
      qtty: qtty - 1
    });
    this.setData(auxData);
  }

  onAddWarehouse = () => {
    const { qtty } = this.state;
    if(qtty === 9)
      return;
    let auxData = this.cloneData();
    auxData.push({ info: "", proyected: "", unit: "", load_type: "" })
    this.setState({
      qtty: qtty + 1
    });
    this.setData(auxData);
  };

  cloneData = () => {
    const { data } = this.state;
    return Object.assign([], data);
  }

  setData = (data) => {
    this.setState({data})
    this.props.onWarehouseDataUpdate(data);
  }

  renderWarehouseForms = () => {
    const { qtty, unitForSelector, data, typesForSelector } = this.state;
    let forms = [];
    let i;
    for (i = 0; i < qtty; i++){
      const n = new Number(i).valueOf();
      forms.push(
        <React.Fragment key={i}>
          <div className="row col-12 mt-2">
            <h5 className="mr-1 mt-4">#{n + 1}</h5>
            <input
              className="form-control col-xs-12 col-md-3 mr-sm-1 mt-1"
              type="text"
              placeholder="Info"
              onChange={e => this.onInfoChange(n, e)}
              value={data[i].info}
            />
            <input
              className="form-control col-xs-12 col-md-3 mt-1 mr-1 mr-sm-0"
              type="number"
              placeholder="Projected"
              onChange={e => this.onProyectedChange(n, e)}
              value={data[i].proyected}
            />
          <div className="col-xs-12 col-sm-3 p-0 col-md-3 ml-1">
              <Select
                value={data[i].load_type}
                options={typesForSelector}
                placeholder={"Type"}
                onChange={e => this.onTypeChange(n, e)}
                className="w-100 mt-1"
              />
            </div>
            <div className="col-xs-12 col-sm-3 p-0 col-md-2 ml-1">
              <Select
                value={data[i].unit}
                options={unitForSelector}
                placeholder={"Unit"}
                onChange={e => this.onUnitChange(n, e)}
                className="w-100 mt-1"
              />
            </div>
          </div>
          <div>
            <span className="badge badge-danger" onClick={() => this.onRemoveWarehouse(n)}>Remove</span>
          </div>
        </React.Fragment>
      );
    }
    return <React.Fragment>{forms.map(e => e)}</React.Fragment>;
  };


  render() {
    return (
      <div>
        <span className="badge badge-primary" onClick={this.onAddWarehouse}>
          Add Hold
        </span>
        {this.renderWarehouseForms()}
      </div>
    );
  }
}

export default OperationWarehouse;
