/* eslint-disable linebreak-style */
import React, { Component } from "react";
import moment from "moment";
import { sendNotification } from "../../service";

class PopUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      text: "",
      disabled: false
    };
  }

  onTitleChange = e => {
    const { value } = e.target;
    this.setState({ title: value });
  };

  onTextChange = e => {
    const { value } = e.target;
    this.setState({ text: value });
  };

  sendNotify = e => {
    e.preventDefault();
    const { title, text } = this.state;
    const { csrf, id } = this.props;
    this.setState({
      disabled: true
    });
    const gmt = moment().utcOffset();
    sendNotification(id, { title, text, gmt }, csrf).then(
      () => this.notificationSuccess(),
      () => this.notificationError()
    );
  };

  notificationError = () => {
    this.setState({
      disabled: false
    });
    alert("An error occurred sending the notification");
  };

  notificationSuccess = () => {
    $("[data-dismiss=modal]").trigger({ type: "click" });
    this.setState({ title: "", text: "", disabled: false });
    alert("Notification succesfully sent");
  };

  render() {
    const { title, text, disabled } = this.state;
    return (
      <React.Fragment>
        <div
          className="btn btn-sm"
          data-toggle="modal"
          data-target="#pop-up-modal"
        >
          Notify
        </div>
        <div className="modal" id="pop-up-modal">
          <div className="modal-dialog">
            <div className="modal-content">
              <form ref="popUpForm" onSubmit={e => this.sendNotify(e)}>
                <div className="modal-header">
                  <h5 className="modal-title">New Notification</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                  />
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="form-group col-12">
                      <label className="form-label">
                        Title
                        <input
                          required={true}
                          className="form-control"
                          onChange={e => this.onTitleChange(e)}
                          value={title}
                        />
                      </label>
                    </div>
                    <div className="form-group col-12">
                      <label className="form-label">
                        Text
                        <textarea
                          required={true}
                          className="form-control"
                          value={text}
                          onChange={e => this.onTextChange(e)}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button 
                    type="submit"
                    disabled={disabled}
                    className="btn btn-primary"
                  >
                    Send
                  </button>
                  <button
                    type="button"
                    data-dismiss="modal"
                    className="btn btn-danger"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PopUpForm;
